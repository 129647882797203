'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useRef } from 'react';
import { FaCircleChevronLeft, FaCircleChevronRight } from 'react-icons/fa6';
import { Button, Title } from 'rizzui';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useSetting } from '@/providers/SettingsProvider';
import { IArticle } from '@/types/article';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const FeaturedArticles = ({ articles }: { articles: IArticle[] }) => {
    const settings = useSetting();
    const sliderRef = useRef<null>(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        //@ts-expect-error
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        //@ts-expect-error
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <div className="flex flex-col w-full">
            <div
                id="feature_product_title"
                className="flex flex-col items-center justify-center h-[150px] bg-gray-100"
            >
                <Title className="font-thin tracking-[.3rem] mb-5">
                    Featured Articles
                </Title>
                <Link href="/articles" aria-label="View all articles">
                    <Button
                        aria-label="View all articles"
                        variant="outline"
                        className="text-gray-900 bg-white tracking-[.1rem]"
                    >
                        View All
                    </Button>
                </Link>
            </div>
            <div className="featured-articles_list">
                <div className="bg-white">
                    <div className="relative px-8 py-4">
                        <Swiper
                            modules={[Navigation, Autoplay]}
                            spaceBetween={20}
                            slidesPerView={1}
                            breakpoints={{
                                640: {
                                    slidesPerView: 3,
                                },
                                1024: {
                                    slidesPerView: 3,
                                },
                            }}
                            navigation={{
                                enabled: true,
                                prevEl: '.custom-article-prev',
                                nextEl: '.custom-article-next',
                            }}
                            autoplay={{
                                delay:
                                    parseInt(settings.site.swiper_delay) * 1000,
                                disableOnInteraction: true,
                            }}
                            loop
                            className="articles-swiper"
                        >
                            {articles.map((item) => (
                                <SwiperSlide key={item.id}>
                                    <Link
                                        href={`/articles/${item.slug}`}
                                        className="relative flex flex-col items-center space-y-2 group"
                                    >
                                        {/* Image with Overlay */}
                                        <div className="relative w-full h-56 overflow-hidden rounded-lg">
                                            <Image
                                                width={400}
                                                height={300}
                                                src={item.media.path}
                                                alt={`Slider ${item.title}`}
                                                className="object-cover w-full h-full"
                                            />
                                            <div className="absolute inset-0 transition-all duration-500 ease-in-out bg-black opacity-50 bg-opacity-30 group-hover:opacity-0"></div>
                                        </div>
                                        {/* Title */}
                                        <p className="text-center text-gray-900 text-[16px]">
                                            {item.title}
                                        </p>
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <button
                            aria-label="Prev articles"
                            onClick={handlePrev}
                            className="absolute z-10 hidden text-white transform -translate-y-1/2 md:block custom-article-prev top-1/2 left-4 sm:left-2 md:left-4 lg:left-10"
                        >
                            <FaCircleChevronLeft className="w-10 h-10" />
                        </button>
                        <button
                            aria-label="Next articles"
                            onClick={handleNext}
                            className="absolute z-10 hidden text-white transform -translate-y-1/2 md:block custom-article-next top-1/2 right-4 sm:right-2 md:right-4 lg:right-10"
                        >
                            <FaCircleChevronRight className="w-10 h-10" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturedArticles;
