'use client';

import parse from 'html-react-parser';
import { Title } from 'rizzui';

import { useSetting } from '@/providers/SettingsProvider';

const AboutUs = () => {
    const settings = useSetting();
    return (
        <div className="flex flex-col w-full scroll-smooth" id="about-us">
            <div
                id="feature_product_title"
                className="flex items-center justify-center h-[100px] bg-gray-100"
            >
                <Title className="font-thin tracking-[.3rem]">About Us</Title>
            </div>
            <div className="flex flex-col items-center justify-center w-full md:py-10 py-5 text-center md:px-40 lg:px:80 px-10 tracking-[.1rem] text-gray-800 font-thin ck-content">
                {parse(settings.site.about_us)}
            </div>
        </div>
    );
};

export default AboutUs;
