import * as yup from 'yup';

export const ContactFormSchema = yup.object().shape({
    name: yup.string().required('Name is required').max(255),
    email: yup
        .string()
        .required('Email is required')
        .email('Must be an valid email')
        .max(255),
    message: yup.string().required('Message is required').max(255),
});

export type ContactFormData = yup.InferType<typeof ContactFormSchema>;
