'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useRef } from 'react';
import { FaCircleChevronLeft, FaCircleChevronRight } from 'react-icons/fa6';
import { Button } from 'rizzui';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useSetting } from '@/providers/SettingsProvider';
import { IBanner } from '@/types/banner';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

interface Props {
    banners: IBanner[];
}

const BannerSlider = ({ banners }: Props) => {
    const settings = useSetting();

    const sliderRef = useRef<null>(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        //@ts-expect-error
        sliderRef.current.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        //@ts-expect-error
        sliderRef.current.slideNext();
    }, []);

    return (
        <div className="banner-container @container">
            <div className="h-[400px] opacity-8 relative" ref={sliderRef}>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={{
                        enabled: true,
                        prevEl: '.custom-prev',
                        nextEl: '.custom-next',
                    }}
                    autoplay={{
                        delay: parseInt(settings.site.swiper_delay) * 1000,
                    }}
                    loop
                    modules={[Autoplay, Navigation]}
                    className="banner-swiper"
                >
                    {banners.map((banner) => {
                        return (
                            <SwiperSlide key={banner.id}>
                                <div className="relative w-full h-[400px]">
                                    <div className="absolute inset-0">
                                        {banner.media && (
                                            <Image
                                                width={1000}
                                                height={600}
                                                src={banner.media.path}
                                                alt="Sale Banner"
                                                className="object-cover w-full h-full"
                                            />
                                        )}
                                    </div>
                                    <div className="absolute inset-0 flex flex-col items-center justify-center gap-2 bg-black bg-opacity-50">
                                        {/* Content Animation */}
                                        <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-gray-300 uppercase tracking-[.5rem] sm:tracking-[.75rem] md:tracking-[1rem] animate-fade-in delay-1000">
                                            {banner.title}
                                        </p>

                                        {/* Title Animation */}
                                        <p className="text-xl sm:text-2xl md:text-3xl text-center text-white uppercase tracking-[.25rem] sm:tracking-[.5rem] animate-fade-in-up delay-500">
                                            {banner.content}
                                        </p>

                                        {/* Button Animation */}
                                        <Link
                                            href={banner.link}
                                            aria-label={banner.title}
                                        >
                                            <Button
                                                variant="outline"
                                                size="lg"
                                                className="delay-700 animate-fade-in-up"
                                            >
                                                <p className="text-lg sm:text-xl md:text-2xl tracking-[.25rem] sm:tracking-[.5rem] text-white uppercase">
                                                    {banner.link_text}
                                                </p>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <button
                    aria-label="Prev Banner"
                    onClick={handlePrev}
                    className="absolute z-10 hidden text-white transform -translate-y-1/2 md:block custom-prev top-1/2 left-4 sm:left-2 md:left-4 lg:left-6"
                >
                    <FaCircleChevronLeft className="w-10 h-10" />
                </button>
                <button
                    aria-label="Next Banner"
                    onClick={handleNext}
                    className="absolute z-10 hidden text-white transform -translate-y-1/2 md:block custom-next top-1/2 right-4 sm:right-2 md:right-4 lg:right-6"
                >
                    <FaCircleChevronRight className="w-10 h-10" />
                </button>
            </div>
        </div>
    );
};

export default BannerSlider;
