'use client';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Button, Input, Text, Textarea, Title } from 'rizzui';

import { storeContactForm } from '@/server/api/contact';
import {
    ContactFormData,
    ContactFormSchema,
} from '@/validations/contact-form-validation';

const ContactUs = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
    } = useForm({
        resolver: yupResolver(ContactFormSchema),
        values: {
            name: '',
            email: '',
            message: '',
        },
    });

    const onSubmit = async (data: ContactFormData) => {
        const resp = await storeContactForm(data);
        if (resp.success) {
            toast.success(<Text as="b">We got your message</Text>);
            reset();
        } else {
            toast.error(<Text as="b">Something went wrong.</Text>);
        }
    };

    return (
        <div className="flex flex-col w-full scroll-smooth" id="contact-us">
            <div className="flex items-center justify-center h-24 bg-gray-100">
                <Title className="font-thin tracking-[.3rem]">Contact Us</Title>
            </div>
            <div className="flex flex-col w-full px-8 py-4">
                <div className="flex flex-col items-center lg:flex-row lg:gap-10">
                    {/* Form Section */}
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col w-full lg:w-1/2 gap-4"
                    >
                        <div className="flex flex-col w-full gap-4 sm:flex-row sm:gap-8">
                            <Input
                                label="Name"
                                labelClassName="text-gray-900 tracking-[.1rem] uppercase"
                                className="w-full"
                                error={errors.name?.message}
                                {...register('name')}
                            />
                            <Input
                                label="Email"
                                labelClassName="text-gray-900 tracking-[.1rem] uppercase"
                                className="w-full"
                                type="email"
                                error={errors.email?.message}
                                {...register('email')}
                            />
                        </div>
                        <div>
                            <Textarea
                                label="Message"
                                labelClassName="text-gray-900 tracking-[.1rem] uppercase"
                                className="w-full"
                                error={errors.message?.message}
                                {...register('message')}
                            />
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <Button
                                aria-label="Submit Contact Form"
                                type="submit"
                                isLoading={isSubmitting}
                                rounded="none"
                            >
                                Send
                            </Button>
                        </div>
                    </form>

                    {/* Google Map Section */}
                    <div className="w-full lg:w-1/2 mt-10 lg:mt-0">
                        <iframe
                            title="Google Map For Evas Art Nepal"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14131.81937544063!2d85.335515!3d27.6877905!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb191347f78e9b%3A0x119202a2634acf4!2sEva&#39;s%20Art%20Nepal!5e0!3m2!1sen!2snp!4v1733570344242!5m2!1sen!2snp"
                            loading="lazy"
                            allowFullScreen
                            className="w-full h-96 border rounded-md"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
