'use client';

import { createContext, ReactNode, useContext } from 'react';

import { ISettings } from '@/types/settings';

const SettingsContext = createContext({});

export const SettingsProvider = ({
    children,
    value,
}: {
    children: ReactNode;
    value: ISettings;
}) => {
    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSetting = () => {
    return useContext(SettingsContext) as ISettings;
};
